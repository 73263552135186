import AuthContext from "./context/AuthProvider"
import {SidebarProvider} from "./context/SidebarContext";
import Login from "./components/Login";
import Main from "./components/Main";
import { useContext } from "react";


function App() {
  const { auth } = useContext(AuthContext);
  return (
    
    <SidebarProvider>
    <main>
      {auth.amnAccessToken ? (
        <Main />
      ) : (
        <Login />
      )}  
    </main>
    </SidebarProvider>
  );
}

export default App;