import React, { useEffect } from "react";
import useFetch from "../api/useFetch";
import { useState } from "react";
import Table from "./Table";
import Loading from "./Loading";

const Technicians = () => {
  const { result: technicians, isLoading: techniciansLoading, error: techniciansError } = useFetch('/technicians', []);
  const tableHeader = [{ content: "First Name", size: "md", isLink: false}, { content: "Last Name", size: "md", isLink: false}, { content: "Location", size: "md", isLink: false}, { content: "Details", size: "sm", isLink: true, link: '/technician'}];
  const [tableContent, setTableContent] = useState(null);

  useEffect(() => {
    if (technicians !== null) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setTableContent(
        technicians.map((item) => [
          item.person.first_name,
          item.person.last_name,
          item.location && item.location.name,
          item.id
        ])
      );
    }
  }, [technicians]);
  

  return (
    <>
        <div div className="list">
        <div className="title">
          TECHNICIANS
        </div>
      {technicians && <Table tableHeader={tableHeader} tableContent={tableContent} />}
      {techniciansLoading && <Loading />}
      {techniciansError && <>{techniciansError}</>}
      </div>
    </>
  );
};

export default Technicians;
