import useFetch from "../api/useFetch";
import usePost from '../api/usePost';
import Loading from "./Loading";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Technician = ({notify}) => {
  const { postData, isLoading } = usePost();
  const { id } = useParams();
  const { result: technician, isLoading: technicianLoading, error: technicianError } = useFetch(`/technician/${id}`, []);
  const [formData, setFormData] = useState({technician_id: null, first_name: null, last_name: null, phone_number: null, email: null, location: null});

  useEffect(() => {
    // Check if technician is loaded and not null
    if (technician) {
      // Update formData with the technician details
      setFormData({
        technician_id: technician.technician_id || null,
        first_name: technician.person.first_name || null,
        last_name: technician.person.last_name || null,
        phone_number: technician.person.phone_number || null,
        email: technician.person.email || null,
        location: technician.location || null
      });
    }
  }, [technician]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log(formData)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await postData('/update_technician', formData);
    if (response) {
        notify('Technician Updated Succesfully')
      
    }
  };

  return (
    <>
        <div div className="list">
        <div className="title">
          TECHNICIAN
        </div>
      {technician && (
        <>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="first_name">First Name</label>
            <br />
            <input type="text" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange}/>
            <br />
            <label htmlFor="last_name">Last Name</label>
            <br />
            <input type="text" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange}/>
            <br />
            <label htmlFor="phone_number">Phone Number</label>
            <br />
            <input type="text" id="phone_number" name="phone_number" value={formData.phone_number} onChange={handleChange}/>
            <br />
            <label htmlFor="email">Email</label>
            <br />
            <input type="text" id="email" name="email" value={formData.email} onChange={handleChange}/>
            <br />
            <button type="submit">
              <i className="fas fa-save" disabled={isLoading}></i>Update
            </button>
          </div>
        </form>
        
        </>
      )}
      {technicianLoading && <Loading />}
      {technicianError && <>{technicianError}</>}
      </div>
    </>
  );
};

export default Technician;
