const Payroll = () => {


  return (
  <>
  <div div className="list">
    <div className="title">PAYROLL</div>
  </div>

  </>
  )
}

export default Payroll