import React from "react";
import TableLine from "./TableLine";

const Table = ({ tableHeader, tableContent }) => {
  return (
    <div className="table">
      <div className="table-header">
        {tableHeader.map((element) => (
          <div key={element.content}>{element.content}</div>
        ))}
      </div>
      <div className="table-body">
        {tableContent && tableContent.map((item) => (
          <div key={item.id} className="table-row">
            <TableLine header={tableHeader} content={item} id={item.id} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;
