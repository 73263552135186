import React, { useEffect, useState } from "react";
import useFetch from "../api/useFetch";
import Table from "./Table";
import Loading from "./Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import funcs from '../utils/utils'

const Appointments = () => {
  const [date, setDate] = useState(new Date());
  const [week, setWeek] = useState('37');
  const [view,setView] = useState('week');
  const [serviceArea, setServiceArea] = useState("all");
  const [technician, setTechnician] = useState("all");
  const { result: items, isLoading: appointmentsLoading, error: appointmentsError } = useFetch(`/appointments?view=${view}&week=${week}&date=${date.toISOString().split('T')[0]}&location=${serviceArea}&technician=${technician}`, [date,serviceArea,technician]);
  const tableHeader = [
    { content: "Client", size: "md", isLink: true, link: '/client' },
    { content: "Technician", size: "md", isLink: true, link: '/technician' },
    { content: "Date", size: "md", isLink: false },
    { content: "Expected Revenue", size: "md", isLink: false },
    { content: "Expected Profit", size: "md", isLink: false },
    { content: "Paid", size: "md", isLink: false },
    { content: "Amount Paid", size: "md", isLink: false },
    { content: "Expected Match", size: "md", isLink: false },
    { content: "Tip", size: "md", isLink: false },
    { content: "Technician Payout", size: "md", isLink: false },
    { content: "Details", size: "md", isLink: true, link: '/appointment' }
  ];
  const [tableContent, setTableContent] = useState(null);

  useEffect(() => {
    if (items !== null) {
      setTableContent(
        items.appointments.filter(item => item.client.person && item.client.person.is_senior_facility === false).map((item) => [
            [item.client && item.client.person && `${item.client.person.first_name ? item.client.person.first_name : '' } ${item.client.person.last_name ? item.client.person.last_name : '' }`,item.client.id],
            [item.technician && item.technician.person && item.technician.person.first_name,item.technician.id],
            funcs.displayDate(item.date_time),
            `$${item.expected_revenue}`,
            `$${item.expected_profit}`,
            item.is_paid,
            item.total_payment ? `$${item.total_payment}` : '-',
            item.total_payment === item.expected_revenue ? true : false,
            item.technician_tip ? `$${item.technician_tip}` : '-',
            item.technician_payout ? `$${item.technician_payout}` : '-',
            item.id
        ])
      );
    }
  }, [items, date]);

  const handlePreviousDay = () => {
    const previousDay = new Date(date);
    previousDay.setDate(date.getDate() - 1);
    setDate(previousDay);
  };

  const handleNextDay = () => {
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);
    setDate(nextDay);
  };

  const filteredTechnicians = () => {
    if (!items || serviceArea === 'all' || serviceArea === '') {
      return items ? items.technicians : [];
    }
    return items.technicians.filter(technician => technician.location.id === parseInt(serviceArea));
  };

  return (
    <div className="list">
      <div className="title">
        APPOINTMENTS
      </div>
      <div className="filters">
        Filters
        <div className="buttons">
          <div className="button">
            <span style={{cursor: 'pointer', opacity: view === 'year' ? 1 : 0.4}} onClick={() => setView('year')}>Year</span>&nbsp;&nbsp;&nbsp;<span style={{cursor: 'pointer', opacity: view === 'month' ? 1 : 0.4}} onClick={() => setView('month')}>Month</span>&nbsp;&nbsp;&nbsp;<span style={{cursor: 'pointer', opacity: view === 'week' ? 1 : 0.4}} onClick={() => setView('week')}>Week</span>&nbsp;&nbsp;&nbsp;<span style={{cursor: 'pointer', opacity: view === 'date' ? 1 : 0.5}} onClick={() => setView('date')}>Date</span>
            <br />
            <br />
              {view === 'date' &&
              <span>
              <i onClick={handlePreviousDay} className="fas fa-chevron-circle-left"></i>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              className="datepicker-input"
            />
            &nbsp;&nbsp;&nbsp;<i onClick={handleNextDay} className="fas fa-chevron-circle-right"></i>
            </span>
            }
            {view === 'week' &&
            <span>
            <select selected={week} onChange={(e) => setWeek(e.target.value)}>
              <option value="37">37</option>
              <option value="36">36</option>
              <option value="35">35</option>
              <option value="34">34</option>
              <option value="33">33</option>
            </select>
            </span>}
            
          </div>
          <div className="button">
            Service Area
            <br />
            <br />
            <select
              value={serviceArea}
              onChange={(e) => setServiceArea(e.target.value)}
              className="service-area-select"
            >
              <option value="">Select Service Area</option>
              {items && items.locations.map(area => (
                <option key={area.id} value={area.id}>
                  {area.name}
                </option>
              ))}
            </select>
          </div>
          <div className="button">
            Technician
            <br />
            <br />
            <select
              value={technician}
              onChange={(e) => setTechnician(e.target.value)}
              className="service-area-select"
            >
              <option value="">Select Technician</option>
              {filteredTechnicians().map(technician => (
                <option key={technician.id} value={technician.id}>
                  {technician.person.first_name} {technician.person.last_name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {items && !appointmentsLoading && !appointmentsError && <Table tableHeader={tableHeader} tableContent={tableContent} />}
      {appointmentsLoading && <Loading />}
      {appointmentsError && <>{appointmentsError} - Please contact Florent</>}
    </div>
  );
};

export default Appointments;
