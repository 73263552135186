import { createContext, useState } from "react";

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    const initialState = {
        amnAccessToken: localStorage.getItem("amnAccessToken") || null,
        amnRefreshToken: localStorage.getItem("amnRefreshToken") || null,
        amnFirstName: localStorage.getItem("amnFirstName") || null,
        amnLastName: localStorage.getItem("amnLastName") || null,
        amnProfilePictureURL: localStorage.getItem("amnProfilePictureURL") || null,
      };
    const [auth,setAuth] = useState(initialState)

    const logout = () => {
        setAuth({ accessToken: null });
        localStorage.removeItem("amnAccessToken");
        localStorage.removeItem("amnRefreshToken");
        localStorage.removeItem("amnFirstName");
        localStorage.removeItem("amnLastName");
        localStorage.removeItem("amnProfilePictureURL");
      };

    return (
        <AuthContext.Provider value={{auth, setAuth, logout}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext