import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useFetch from "../api/useFetch";
import Table from "./Table";
import Loading from "./Loading";
import { Link } from 'react-router-dom';
import funcs from '../utils/utils';
import usePost from '../api/usePost';
import { useCallback } from 'react';


const Appointment = ({ notify }) => {
  const { postData } = usePost();
  const [refresh, setRefresh] = useState(false);
  const { id } = useParams();
  const { result: appointment, isLoading: appointmentLoading, error: appointmentError } = useFetch(`/appointment/${id}`, [refresh]);
  
  const [servicesTableContent, setServicesTableContent] = useState(null);
  const [transactionsTableContent, setTransactionsTableContent] = useState(null);
  const [possibleTransactionsTableContent, setPossibleTransactionsTableContent] = useState(null);
  const [showAdditionalPayments, setShowAdditionalPayments] = useState(false);

  const match_transaction = useCallback(async (transaction_id) => {
    const data = { appointment_id: appointment.data.id, transaction_id: transaction_id };
    const response = await postData('/match_transaction', data);
  
    if (response) {
      notify(response.message);
      setRefresh((prev) => !prev); // Use function form to toggle the refresh state
    }
  }, [appointment, postData, notify]);

  const servicesTableHeader = [
    { content: "Service", size: "md", isLink: false },
    { content: "Cost", size: "sm", isLink: false },
    { content: "Technician Payout", size: "sm", isLink: false },
  ];

  const transactionsTableHeader = [
    { content: "Date", size: 'sm', isLink: false},
    { content: "Client", size: "md", isLink: true, link: '/client' },
    { content: "Amount", size: "sm", isLink: false },
    { content: "Tip", size: "sm", isLink: false },
    { content: "Remove", size: "sm", isLink: false }
  ];

  const possibleTransactionsTableHeader = [
    { content: "Date", size: 'sm', isLink: false},
    { content: "Client", size: "md", isLink: true, link: '/client' },
    { content: "Amount", size: "sm", isLink: false },
    { content: "Tip", size: "sm", isLink: false },
    { content: "Add", size: "sm", isLink: false }
  ];

  useEffect(() => {
    if (appointment && appointment.data && appointment.data.services) {
      setServicesTableContent(
        appointment.data.services.map((item) => [
          item.service?.name,
          item.service ? `$${item.service.price}` : '',
          item.service ? `$${item.service.split_amount}` : '',
        ])
      );
    }
  }, [appointment]);

  useEffect(() => {
    if (appointment && appointment.data && appointment.data.transactions) {
      setTransactionsTableContent(
        appointment.data.transactions.map((item) => [
          funcs.displayDate(item.date_time),
          item.client && [item.client.person && `${item.client.person.first_name ? item.client.person.first_name : '' } ${item.client.person.last_name ? item.client.person.last_name : '' }`, item.client.id],
          `$${item.amount}`,
          item.tip ? `$${item.tip}` : '$0',
          <i className="fas fa-trash-alt"></i>,
        ])
      );
      
      setPossibleTransactionsTableContent(
        appointment.possible_transactions.map((item) =>  [
          funcs.displayDate(item.date_time),
          item.client && [item.client.person && `${item.client.person.first_name ? item.client.person.first_name : '' } ${item.client.person.last_name ? item.client.person.last_name : '' }`, item.client.id],
          `$${item.amount}`,
          item.tip ? `$${item.tip}` : '$0',
          <i onClick={() => match_transaction(item.id)} className="fas fa-plus"></i>,
        ])
      );
    }
  }, [appointment, match_transaction]);

  

  return (
    <div className="list">
      <div className="title">APPOINTMENT</div>

      {appointment && (
        <center>
          <div>
            <b>Date</b><br /> {funcs.displayDate(appointment.data.date_time)}
          </div>
          <br />
          <div>
            <b>Time</b><br /> {funcs.displayTime(appointment.data.date_time)}
          </div>
          <br />
          <div>
            <b>Client</b><br />
            {appointment.data.client?.person && (
              <Link to={`/client/${appointment.data.client.id}`}>
                {appointment.data.client.person.first_name} {appointment.data.client.person.last_name}
              </Link>
            )}
          </div>
          <br />
          <div>
            <b>Technician</b><br />
            {appointment.data.technician?.person && (
              <Link to={`/technician/${appointment.data.technician.id}`}>
                {appointment.data.technician.person.first_name} {appointment.data.technician.person.last_name}
              </Link>
            )}
          </div>
          <br />
          <br />
          <b>Services</b><br /><br />
          <Table tableHeader={servicesTableHeader} tableContent={servicesTableContent} />
          <br /><br /><br />
          
          {appointment.data.transactions?.length > 0 && (
            <>
              <b>Payments</b><br /><br />
              <Table tableHeader={transactionsTableHeader} tableContent={transactionsTableContent} />
            </>
          )}

          <br /><br /><br />
          {!showAdditionalPayments && (
            <span className="button" onClick={() => setShowAdditionalPayments(true)}>Add Payment</span>
          )}
          {showAdditionalPayments && (
            <>
              <b>Select Payment</b><br /><br />
              <Table tableHeader={possibleTransactionsTableHeader} tableContent={possibleTransactionsTableContent} />
            </>
          )}
        </center>
      )}

      {appointmentLoading && <Loading />}
      {appointmentError && <>{appointmentError} - Please contact Florent</>}
    </div>
  );
};

export default Appointment;
