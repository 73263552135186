import { useRef, useState, useEffect, useContext } from 'react'
import AuthContext from '../context/AuthProvider';
import '../css/login.css';
import Logo from '../static/img/amn_logo.png';
import axios from '../api/axios';
import API_BASE_URL from '../api/config';


const LOGIN_URL = `${API_BASE_URL}/login/`



const Login = () => {
    const { auth, setAuth } = useContext(AuthContext)
    const userRef = useRef()
    const errRef = useRef()

    const [user, setUser] = useState('')
    const [pwd, setPwd] = useState('')
    const [errMsg, setErrMsg] = useState(false)

    useEffect(() => {
        console.log(auth);
      }, [auth]);

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            console.log(JSON.stringify({"username": user,"password": pwd}))
            const response = await axios.post(LOGIN_URL, JSON.stringify({"username": user,"password": pwd}), {headers: {'Content-Type': 'application/json'}, withCredentials: true})
            console.log(response)
            console.log(response?.data)
            const { amnAccess_token, amnRefresh_token, amnFirst_name, amnLast_name, amnProfile_picture_url } = response.data;
            setAuth({amnAccessToken: amnAccess_token, amnRefreshToken: amnRefresh_token, amnFirstName: amnFirst_name, amnLastName: amnLast_name, amnProfilePictureURL: amnProfile_picture_url})
            localStorage.setItem("amnAccessToken", amnAccess_token);
            localStorage.setItem("amnRefreshToken", amnRefresh_token);
            localStorage.setItem("amnFirstName", amnFirst_name);
            localStorage.setItem("amnLastName", amnLast_name);
            localStorage.setItem("amnProfilePictureURL", amnProfile_picture_url);
            setUser('')
            setPwd('')
            setErrMsg(false)
        } catch (err) {
            console.log(err)
            if(!err?.response) {
                setErrMsg('No Server Response')
            } else if (err.response?.status === 400 ){
                setErrMsg('Invalid Login or Password')
            }
            else {
                setErrMsg('Login Failed')
            }
            errRef.current.focus()
        }

    } 

  return (
    <div className="container">
        <div className="image-box-container">
            <img src={Logo} alt="Epytis Logo" />
            <div className="box">
                <div className="icon-container">
                    <i className="fas fa-user icon"></i>
                </div>
                <p><b>Login</b></p>
                <p>Please fill in the details below to connect</p>
                <p ref={errRef} className={errMsg ? "error" : "hidden"} aria-live="assertive">{errMsg ? errMsg : ""}</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <div className="form-control">
                            <input type="text" placeholder="Username" id='username' ref={userRef} autoComplete='off' required
                                onChange= {(e) => setUser(e.target.value)}
                                value={user}
                            />
                            <i className="fas fa-user form-icon"></i>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-control">
                            <input type="password" placeholder="Password" id='password' required
                                onChange= {(e) => setPwd(e.target.value)}
                                value={pwd}
                            />
                            <i className="fas fa-lock form-icon"></i>
                        </div>
                    </div>
                    <button>Sign In</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Login