import { Link } from "react-router-dom";

const TableLine = ({ header, content, id }) => {
  return (
    <>
      {content &&
        content.length > 0 &&
        content.map((item, index) => (
            <>
            {!(typeof item === 'boolean') && !header[index].isLink && <div key={index}>{item}</div>}
            {!(typeof item === 'boolean') && header[index].isLink && header[index].content !== "Details" && <Link to={`${header[index].link}/${item[1]}`}>{item[0]}</Link>}
            {!(typeof item === 'boolean') && header[index].isLink && header[index].content === "Details" && <Link to={`${header[index].link}/${item}`}><i class="fas fa-eye"></i></Link>}
            {typeof item === 'boolean' && item === true && <div><i style={{color: 'green'}} class="fas fa-check"></i></div>}
            {typeof item === 'boolean' && item === false && <div><i style={{color: 'red'}} class="fas fa-times"></i></div>}
          </>
        ))}
    </>
  );
};

export default TableLine;
