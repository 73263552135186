import React, { useEffect } from "react";
import useFetch from "../api/useFetch";
import { useState } from "react";
import Table from "./Table";
import Loading from "./Loading";

const Locations = () => {
  const { result: locations, isLoading: locationsLoading, error: locationsError } = useFetch('/locations', []);
  const tableHeader = [{ content: "Area", size: "md", isLink: false}, { content: "Details", size: "sm", isLink: true, link: 'location'}];
  const [tableContent, setTableContent] = useState(null);

  useEffect(() => {
    if (locations !== null) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setTableContent(
        locations.map((item) => [
          item.name,
          item.id
        ])
      );
    }
  }, [locations]);
  

  return (
    <>
          <div div className="list">
        <div className="title">
          LOCATIONS
        </div>
      {locations && <Table tableHeader={tableHeader} tableContent={tableContent} />}
      {locationsLoading && <Loading />}
      {locationsError && <>{locationsError}</>}
      </div>
    </>
  );
};

export default Locations;
