// import React, { useEffect } from "react";
import useFetch from "../api/useFetch";
import usePost from '../api/usePost';
import { useState, useEffect } from "react";
import Request from "./Request";
import Loading from "./Loading";

const Requests = ({notify}) => {
  const { postData, error } = usePost();
  const [refresh,setRefresh] = useState(false)
  const { result: requests, isLoading: requestsLoading, error: requestsError } = useFetch('/requests', [refresh]);
  const [switchPosition,setSwitchPosition] = useState("internal")

  useEffect(() => {
    if (requests && requests.phone) {
      setSwitchPosition(requests.phone);
    }
  }, [requests]);

  const switch_phone = async () => {
    const data = {phone: switchPosition}
    const response = await postData('/switch_phone', data);
    console.log(error)
    if (response) {
      console.log(response)
        notify(response.message)
        switchPosition === 'external' ? setSwitchPosition('internal') : setSwitchPosition('external')
    }
  }

  return (
<>
{!requestsLoading && requests && (
    <div className="switch-container">
    <div className="toggle-switch">
      <input type="checkbox" className="checkbox" 
             name='unit' id='unit' checked={switchPosition === 'external'}/>
      <label onClick={switch_phone} className="label" htmlFor='unit'>
        <span className="inner" />
        <span className="switch" />
      </label>
    </div>
  </div>)}
      <div div className="list">
        <div className="title">
          REQUESTS
        </div>
      {!requestsLoading && requests && requests.items.length > 0 && requests.items.map((request) => <Request request_data={request} notify={notify} setRefresh={setRefresh} refresh={refresh}/> )}
      {!requestsLoading && requests && requests.items.length === 0 && <div><center>Good Job ! All Requests Have Been Processed</center></div>}
      {requestsLoading && <Loading />}
      {requestsError && <>{requestsError}</>}
      </div>
      </>
  );
};

export default Requests;
