const funcs = {
    displayDate: (dateTimeString) => {
      const date = new Date(dateTimeString);
      const year = date.getUTCFullYear();
      const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      const day = date.getUTCDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  
    displayTime: (dateTimeString) => {
      const utcDate = new Date(dateTimeString);
      
      // Convert UTC time to EST (Eastern Standard Time)
      const offset = -5; // EST is UTC-5
      const estDate = new Date(utcDate.getTime() + offset * 60 * 60 * 1000);
      
      const hours = estDate.getUTCHours().toString().padStart(2, '0');
      const minutes = estDate.getUTCMinutes().toString().padStart(2, '0');
  
      return `${hours}:${minutes}`;
    },

    weekSelector: () => {
      const weeks = [];
    
      for (let i = 53; i >= 1; i--) {
        weeks.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
    
      return (
        <>
          {weeks}
        </>
      );
    },
   
  yearSelector: () => {
    const currentYear = new Date().getFullYear();
    const years = [];
  
    for (let i = 0; i < 4; i++) {
      years.push(
        <option key={currentYear - i} value={currentYear - i}>
          {currentYear - i}
        </option>
      );
    }
  
    return (
        <>
        {years}
        </>
    );
  }
  }
  
  export default funcs;
  