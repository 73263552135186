import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import '../css/main.css'; 
import SidebarItem from './SidebarItem';
import SidebarContext from '../context/SidebarContext';


const Sidebar = () => {
    const { collapsed, toggleCollapsed } = useContext(SidebarContext);

    const menuItems = [
        { icon: 'fa-home', text: 'HOME', to: '/' },
        { icon: 'fa-calendar-check', text: 'APPOINTMENTS', to: '/appointments' },
        { icon: 'fa-user-check', text: 'CLIENTS', to: '/clients' },
        { icon: 'fa-user-cog', text: 'TECHNICIANS', to: '/technicians' },
        { icon: 'fa-money-check-alt', text:'PAYROLL', to: '/payroll' },
        { icon: 'fa-user-plus', text: 'HIRING', to: '/hiring' },
        { icon: 'fa-question-circle', text:'REQUESTS', to: '/requests' },
        { icon: 'fas fa-mail-bulk', text:'OUTREACH', to: '/outreach' },
        { icon: 'fa-user-friends', text: 'LEADS', to: '/leads' },
        { icon: 'fa-folder', text: 'SERVICES', to: '/services' },
        { icon: 'fa-thumbtack', text: 'LOCATIONS', to: '/locations' },
        { icon: 'fa-headset', text:'QUALITY', to: '/quality' },
        { icon: 'fa-chart-line', text:'METRICS', to: '/metrics' }
      ];

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
        <i className="fas fa-bars sidebar-btn" onClick={toggleCollapsed}></i>
        <div className={`sidebar-content ${collapsed ? 'collapsed' : ''}`}>
        {menuItems.map((item) => (
          <SidebarItem
            key={item.text}
            icon={item.icon}
            text={item.text}
            to={item.to}
          />
        ))}
        </div>
        <div className="sidebar-bottom">
            <Link to="/">
            <i className="fas fa-cog"></i>
            </Link>
        </div>
        </div>
    );
};

export default Sidebar;