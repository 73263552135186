import { useParams } from 'react-router-dom';
import useFetch from "../api/useFetch";
import Loading from "./Loading";


const Client = () => {
    const { id } = useParams();
    const { result: client, isLoading: clientLoading, error: clientError } = useFetch(`/client/${id}`, []);
    return (
        <div className="list">
          <div className="title">
            CLIENT
          </div>
          {client && (
          <center>
              <div>
                <b>First Name</b><br />{client.person && client.person.first_name ? client.person.first_name : ''}
              </div>
              <div>
                <b>Last Name</b><br />{client.person.last_name ? client.person.last_name : ''}
              </div>
              <div>
                <b>Phone Number</b><br /> {client.person && client.person.phone_number}
              </div>
              <div>
                <b>Email</b><br /> {client.person && client.person.email}
              </div>
              </center>)}
          {clientLoading && <Loading />}
          {clientError && <>{clientError} - Please contact Florent</>}
        </div>
      );

};

export default Client;
