import React from 'react';
import { Link } from 'react-router-dom';

const SidebarItem = ({ icon, text, to, onClick }) => {

  return (
    <Link className={'sidebar-item'} onClick={onClick} to={to}>
      <i className={`fas ${icon}`}></i>
      <span className="sidebar-text">{text}</span>
    </Link>
  );
};

export default SidebarItem;