import React, { useEffect } from "react";
import useFetch from "../api/useFetch";
import { useState } from "react";
import Table from "./Table";
import Loading from "./Loading";

const Services = () => {
  const { result: services, isLoading: servicesLoading, error: servicesError } = useFetch('/services', []);
  const tableHeader = [{ content: "Name", size: "lg", isLink: false}, { content: "Price", size: "sm", isLink: false}, { content: "Split Type", size: "sm", isLink: false}, { content: "Split %", size: "sm", isLink: false}, { content: "Split $", size: "sm", isLink: false}, { content: "Active", size: "sm", isLink: false}, { content: "Details", size: "sm", isLink: true, link: '/service'}];
  const [tableContent, setTableContent] = useState(null);

  useEffect(() => {
    if (services !== null) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setTableContent(
        services.map((item) => [
          item.name,
          `$${item.price}`,
          item.split_type,
          `${item.split_percentage}%`,
          `$${item.split_amount}`,
          item.active,
          item.id
        ])
      );
    }
  }, [services]);
  

  return (
    <>
          <div div className="list">
        <div className="title">
          SERVICES
        </div>
      {services && <Table tableHeader={tableHeader} tableContent={tableContent} />}
      {servicesLoading && <Loading />}
      {servicesError && <>{servicesError}</>}
      </div>
    </>
  );
};

export default Services;
